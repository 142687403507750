// Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '@morioh/v-lightbox/dist/lightbox.css';
@import '~vuejs-dialog/dist/vuejs-dialog.min.css';

//Custom
@import 'custom';

body {
    font-size: 1rem;
}

main {
    min-height: calc(100vh - 252px);
}

.form-control {
    padding-left: 0;
    border-top: none;
    border-right: none;
    border-bottom: #C0C0C0 solid 1px;
    border-left: none;
    box-shadow: none !important;
    border-radius: 0;
}

label {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.2;
}

.text-justify {
    text-align: justify;
}

.card {
    border-radius: 1.5rem;
    -webkit-box-shadow: 0 1rem 2rem 0 rgb(0 0 0 / 5%);
    box-shadow: 0 1rem 2rem 0 rgb(0 0 0 / 5%);
    border: none;
}

.form-group {
    margin-bottom: 1.5rem;
}

.btn-block {
    display: block;
    width: 100%;
}

nav {
    .btn-link {
        // font-size: 18px;
    }
}

.fs10 {
    font-size: 10px !important;
}

.fs12 {
    font-size: 12px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs-34 {
    font-size: 34px !important;
}

.fs-42 {
    font-size: 42px !important;
}

.btn-sm {
    padding: 8px 12px;
}

.advertisement__description .desc {
    word-break: break-word;
    text-align: justify;
}

.advertisement__description .desc br {
    display: none !important;
}

.advertisement__description .desc h1,
.advertisement__description .desc h2,
.advertisement__description .desc h4,
.advertisement__description .desc h5 {
    font-size: 1rem !important;
}

.advertisement__description .desc h3 {
    font-size: 1.4rem;
}


.listing_card_favorites {
    position: absolute;
    background: #fff;
    color: #333;
    padding: 2px 8px 0px 4px;
    right: 35px;
    margin-top: 41px;
    z-index: 2;
    font-size: 18px;
    border-radius: 25px;

    button {
        border: none;
        background: none;
        outline: none;
        padding: 0;
        font-size: 1.2rem;

        &:hover {
            // background: red;
            color: red;
        }

        &.active {
            &:hover {
                background: red;
                color: red;
            }
        }
    }
}

@media(max-width: 992px) {
    .row {
        // padding: 0!important;
    }
}

.list-itm {
    position: relative;

    .add-to-favorites-container {
        position: absolute;
        right: 25px;
        z-index: 2;
        font-size: 13px;
        top: 25px;
        font-weight: 500;
        border-radius: 12px;

        @media(max-width: 768px) {
            right: 35px;
        }
    }

    .premium_card_stars {
        position: absolute;
        background: #fff;
        color: #333;
        padding: 0px 7px;
        left: 25px;
        z-index: 2;
        font-size: 13px;
        top: 55px;
        font-weight: 500;
        border-radius: 12px;

        i {
            border: solid 1px #fff;
        }

        @media(max-width: 768px) {
            left: 35px;
        }
    }

    .premium_card_notification {
        position: absolute;
        background: #fff;
        color: #333;
        padding: 0px 7px;
        left: 25px;
        top: 25px;
        z-index: 2;
        font-size: 13px;
        font-weight: 500;
        border-radius: 12px;

        @media(max-width: 768px) {
            left: 35px;
        }
    }
}

.list-itm a {
    text-decoration: none !important;
}

.dg-content {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}

.dg-btn--ok {
    color: #333;
    ;
    border-color: #333;
}

.dg-btn--cancel {
    background-color: $primary;
}

#sidebar_filter h3 {
    margin-top: 4px;
}

#promo-sidebar {
    box-shadow: 0 1rem 2rem 0 rgb(0 0 0 / 5%);
    margin-top: 45px;
    padding-top: 25px;
    padding-bottom: 12px;
    border-radius: 1.5rem;

    img {
        border-radius: 12px;
        max-width: 100%;
    }
}

.listing-links:hover {
    background: $primary;
    border-color: $primary;
    color: #333;
}

#mobile-category-search {
    i {
        top: 43px;
        font-size: 18px;
    }

    input,
    select {
        color: #333;
        margin-top: 5px !important;
    }

    label {
        margin-left: 0 !important;
        position: relative;
        top: 0;
    }
}

@media(max-width: 992px) {
    .hero__category-page h1 {
        text-align: center;
    }

    #listing-inner-content {
        padding: 0;
    }
}

#gdpr {
    display: none;
    z-index: 99999999999;
    width: 100%;
    padding: 12px 20px;
    background: #fff;
    color: #333;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    a {
        color: $primary;
    }

    .btn {
        float: right;
    }
}

.mw-20 {
    min-width: 25px;
}

.btn-fav {
    cursor: pointer;

    &:focus {
        background-color: $primary;
        color: #333;
        border-color: rgb(244, 219, 77);
        outline: inherit;
        box-shadow: none;
    }

    @media(max-width: 768px) {
        &:hover {
            background-color: $primary;
            color: #333;
            border-color: rgb(244, 219, 77);
            outline: inherit;
            box-shadow: none;
        }
    }
}

.underline-none {
    text-decoration: none !important;
}

@media(max-width: 768px) {
    nav .navbar-brand img {
        height: 2.4rem;
    }
}

.no-underline {
    text-decoration: none;
}

.seo-wrap br {
    display: none;
}

@media(min-width: 1200px) and (max-width:1400px) {
    nav.navigation__homepage .navbar-brand img {
        height: 3rem;
    }

    .navbar-nav {
        gap: 15px !important;

        .nav-item {
            .btn {
                padding: 6px !important;
            }
        }
    }
}

#mobile-category-actions {
    display: none;

    @media(max-width: 768px) {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        border-top: solid 1px #e8e8e8;
        box-shadow: 4px 2px 5px 0px #333;
        padding: 15px;
        z-index: 1000;
    }
}

.w-32 {
    width: 32px;
}

.w-64 {
    width: 64px;
}

.h6 {
    font-size: 1rem;
}

@media(max-width: 768px) {
    .pagination {
        display: unset !important;

        li {
            display: inline-flex;
        }
    }
}

.header-bottom-nav {
    overflow: scroll;
    margin-top: 12px;
    display: none;

    @media(max-width: 768px) {
        display: block;
    }

    .nav-submenu {
        flex-wrap: nowrap;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 12px;

        li {
            font-size: 9px;
            margin-right: 12px;

            a {
                color: #333;
                padding: 0;
                width: max-content;
            }
        }
    }
}

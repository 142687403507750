// Custom Variables

$custom-box-shadow__card: 0 1rem 2rem 0 rgba(0, 0, 0, 0.05);
$custom-box-shadow__primary-button--default: 0 1rem 2rem 0 rgba(244, 219, 77, 0.4);
$custom-box-shadow__primary-button--hover: 0 1rem 2rem 0 rgba(51, 51, 51, 0.2);
$custom-color__primary: rgba(244, 219, 77, 1);
$custom-color__secondary: rgb(145, 147, 151);
$custom-color__border: rgb(192, 192, 192);
$custom-color__body: rgb(51, 51, 51);
$custom-color__body--faded: rgb(153, 153, 153);

$transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

// Variables

@media (min-width: 1400px) {

    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1600px;
    }
}

// --------------------------------------------------

// Layout

// --- Containers

.container {
    &.custom__carousel-with-cards {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #F9F9F9;
            border-radius: 1.5rem;
            content: '';

            @media(max-width: 992px) {
                left: 0;
                width: 100%;
            }
        }

        .carousel {
            &.slide {
                position: relative;

                .carousel-control-prev,
                .carousel-control-next {
                    top: unset;
                    bottom: -3.5rem;
                    width: 3.75rem;
                    height: 3.75rem;

                    background: $custom-color__primary;
                    border-color: $custom-color__primary;
                    border-radius: 0.5rem;
                    box-shadow: $custom-box-shadow__primary-button--default;
                    color: $custom-color__body;
                    opacity: 1;
                    transition: $transition;

                    @media (min-width: 992px) {
                        top: -3.5rem;
                        bottom: unset;
                    }

                    &:hover,
                    &:focus {
                        background-color: $custom-color__body;
                        border-color: $custom-color__body;
                        box-shadow: $custom-box-shadow__primary-button--hover;
                        color: white;
                    }
                }

                .carousel-control-prev {
                    left: 1.5rem;
                    right: unset;

                    @media (min-width: 992px) {
                        left: unset;
                        right: 7.25rem;
                    }
                }

                .carousel-control-next {
                    right: 1.5rem;
                }
            }
        }
    }
}

.section__hero {
    &.hero__homepage {
        position: relative;
        min-height: 360px;
        display: grid;
        align-items: center;

        @media (min-width: 992px) {
            min-height: 720px;
            margin-top: -174px;
        }

        figure {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0 0 1.5rem 1.5rem;
                content: '';
                z-index: 2;

                @media(max-width: 992px) {
                    left: 0;
                    width: 100%;
                }

                @media (min-width: 992px) {
                    background: rgb(255, 255, 255);
                    background: linear-gradient(135deg, rgba(255, 255, 255, 0.66) 0%, rgba(255, 255, 255, 0) 75%);
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0 0 1.5rem 1.5rem;
                object-fit: cover;
                pointer-events: none;
                z-index: 1;

                @media (max-width: 991px) {
                    filter: brightness(0.5);
                    object-position: right;
                    left: 0;
                    width: 100%;
                }
            }

            figcaption {
                position: relative;
                z-index: 3;

                @media (min-width: 992px) {
                    padding-top: 87px;
                }

                @media (max-width: 991px) {
                    * {
                        color: white;
                    }
                }
            }
        }
    }

    &.hero__service-page {
        position: relative;
        min-height: 620px;
        display: grid;
        align-items: end;

        figure {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgb(0, 0, 0);
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 100%);
                border-radius: 0 0 1.5rem 1.5rem;
                content: '';
                z-index: 2;

                @media(max-width: 992px) {
                    left: 0;
                    width: 100%;
                }
            }

            h1 {
                position: relative;

                &::before {
                    position: absolute;
                    left: 0;
                    bottom: calc(-1.5rem - 3px);
                    width: 100%;
                    height: 1px;
                    background: #919397;
                    content: '';
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0 0 1.5rem 1.5rem;
                object-fit: cover;
                pointer-events: none;
                z-index: 1;

                @media(max-width: 992px) {
                    left: 0;
                    width: 100%;
                }
            }

            figcaption {
                position: relative;
                z-index: 3;
            }
        }

        * {
            color: white;
        }
    }

    &.hero__category-page {
        position: relative;
        min-height: 320px;
        display: grid;
        align-items: end;

        @media(max-width: 992px) {
            min-height: 145px;
        }

        figure {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgb(0, 0, 0);
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 100%);
                border-radius: 0 0 1.5rem 1.5rem;
                content: '';
                z-index: 2;

                @media(max-width: 992px) {
                    left: 0;
                    width: 100%;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 0 0 1.5rem 1.5rem;
                object-fit: cover;
                pointer-events: none;
                z-index: 1;

                @media(max-width: 992px) {
                    left: 0;
                    width: 100%;
                }
            }

            figcaption {
                position: relative;
                z-index: 3;
            }
        }

        * {
            color: white;
        }
    }
}

.section__search-form {
    position: relative;

    @media (min-width: 992px) {
        margin-top: -7.25rem;
        z-index: 10;
    }

    .form-select,
    .form-control {
        padding-left: 0;
        border-top: none;
        border-right: none;
        border-bottom: #C0C0C0 solid 1px;
        border-left: none;
        border-radius: 0;
    }
}

// --- Sidebar

.layout__sidebar,
.custom__form_layout {

    &.sidebar__service-page,
    &.sidebar__category-page {
        padding: 2rem;
        border-radius: 1.5rem;
        box-shadow: $custom-box-shadow__card;
    }

    &.sidebar__category-page {
        // .form-select {
        //     padding-left: 0;
        //     border-top: none;
        //     border-right: none;
        //     border-bottom: #C0C0C0 solid 1px;
        //     border-left: none;
        //     border-radius: 0;
        // }

        label {
            padding: 0.5rem 1.65rem;
            margin: 0 0 0 0.25rem;
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
        }

        .form-select {
            padding: 1rem 1.5rem;
            margin-top: -1.25rem;
            border: $custom-color__border solid 1px;
            border-radius: 1.5rem;

            &::placeholder {
                color: $custom-color__secondary;
            }
        }
    }
}

// --------------------------------------------------

// Content

// --- Typography

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
}

// --- Images

.image__frame {
    @media (min-width: 992px) {
        outline: white solid 1rem;
        outline-offset: -0.95rem;
    }
}

// --------------------------------------------------

// Forms

// --- Search Form

.form__search {
    position: relative;
    width: 100%;
    max-width: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
    grid-column-gap: 3rem;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        grid-row-gap: 1.5rem;
        max-width: 100%;
    }

    &::before {
        position: absolute;
        top: 0;
        left: -5rem;
        width: calc(100% + 10rem);
        height: 100%;
        background: white;
        border-radius: 1.5rem;
        box-shadow: $custom-box-shadow__card;
        content: '';

        @media(max-width: 992px) {
            left: 0;
            width: 100%;
        }
    }

    .form__field {
        position: relative;
        width: 100%;

        &:not(.form__button) {
            max-width: 15rem;

            @media (max-width: 991px) {
                max-width: 100%;
            }
        }
    }
}

.form__contact-form,
.form__ad {
    label {
        padding: 0.5rem 1.25rem;
        margin: 0 0 0 0.25rem;
        background: rgb(255, 255, 255);
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 1) 85%, rgba(255, 255, 255, 0) 100%);
    }

    input,
    select,
    textarea {
        padding: 1rem 1.5rem;
        margin-top: -1.25rem;
        border: $custom-color__border solid 1px;
        border-radius: 1.5rem;

        &::placeholder {
            color: $custom-color__secondary;
        }
    }

    .form__confirmation {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: 2rem;
        align-items: start;

        @media (max-width: 1399px) {
            grid-template-columns: 1fr;
            grid-row-gap: 1.5rem;
        }

        p {
            margin: 0;
        }
    }
}

// --------------------------------------------------

// Components

// --- Buttons

.btn {
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    line-height: 1.25;

    &.btn-link {
        padding: 0;

        &:hover,
        &:focus {
            color: $custom-color__body--faded;
        }
    }
}

.btn-primary {
    background: $custom-color__primary;
    border-color: $custom-color__primary;
    box-shadow: $custom-box-shadow__primary-button--default;
    color: $custom-color__body;

    &:hover,
    &:focus {
        background-color: $custom-color__body;
        border-color: $custom-color__body;
        box-shadow: $custom-box-shadow__primary-button--hover;
        color: white;
    }
}

// --- Card

.card__advertisement {
    position: relative;
    display: grid;
    align-content: end;
    min-height: 474.67px;
    height: 100%;
    background: white !important;
    border-radius: 1.5rem;
    box-shadow: $custom-box-shadow__card;
    transition: 0.33s ease-in;

    img {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        border-radius: 1rem;
        filter: brightness(0.8);
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        transition: 0.33s ease-in;
    }

    figcaption {
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-column-gap: 2rem;
        grid-template-areas: "premium premium premium"
            "location location location"
            "ad-title ad-title ad-title"
            "available-space price button-advertisement";
        padding: 3rem;
        color: white;
        background: linear-gradient(360deg, black, transparent);
        width: calc(100% - 2rem);
        margin: auto;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition: 0.5s ease-out;
        // background: linear-gradient(0deg, black, transparent);
    }

    .card__location {
        grid-area: location;
    }

    .card__premium {
        grid-area: premium;
    }

    .card__ad-title {
        position: relative;
        grid-area: ad-title;

        &::before {
            position: absolute;
            left: 0;
            bottom: calc(-0.75rem - 3px);
            width: 100%;
            height: 1px;
            background: #919397;
            content: '';
        }
    }

    .card__available-space {
        grid-area: available-space;
    }

    .card__price {
        grid-area: price;
    }

    .button__advertisement {
        grid-area: button-advertisement;
        padding: 0;
        color: white;
    }

    .card__location,
    .card__available-space,
    .card__price {
        font-weight: bold;
    }

    &:hover {
        box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.25);
        transition: 0.33s ease-out;

        figcaption {
            width: 100%;
            transition: 0.33s ease-out;
        }

        @media(min-width: 768px) {
            img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 1.5rem;
                transition: 0.33s ease-out;
                filter: brightness(0.95);
            }
        }
    }
}

// --- Contact Info

.contact-info__service-provider {
    border-top: $custom-color__border solid 1px;
    border-bottom: $custom-color__border solid 1px;

    .service-provider__name,
    .service-provider__phone,
    .service-provider__email {
        margin: 0;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}

.service-info__service-details {
    position: relative;

    .service-details__service-type,
    .service-details__available-space,
    .service-details__service-price,
    .service-details__service-address {
        font-weight: bold;
    }
}

// --- Carousel

.custom__carousel-with-cards {
    .carousel {
        &.slide {
            position: relative;
        }
    }
}

.carousel__ad-gallery {
    padding: 1rem;
    border-radius: 1.5rem;
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, .05);

    .carousel-inner {
        position: relative;
        border-radius: 1rem;
        overflow: hidden;

        &::after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 6rem;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            content: '';
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
    }

    .carousel-indicators {
        margin: 0 auto 2.5rem;

        [data-bs-target] {
            margin: 0 0.5rem;
            width: 1rem;
            height: 1rem;
            background-color: white;
            border-radius: 50%;
            opacity: 1;

            &.active {
                background-color: $custom-color__primary
            }
        }
    }
}

// --- Iconbox

.component__iconbox {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 12px;

    .iconbox__icon {
        i {
            display: grid;
            place-items: center;
            width: 3.75rem;
            height: 3.75rem;
            background: white;
            border-radius: 0.5rem;
            box-shadow: 0 0.5rem 2rem 0 rgba(145, 147, 151, 0.1);
            color: #a7a7a7;
            font-size: 24px;
        }
    }

    // .iconbox__metadata {

    // }

    &.iconbox--vertically-centered {
        align-items: center;
    }

    &.iconbox__icons-outside {
        @media (min-width: 1200px) {
            grid-template-columns: 1fr;
        }

        .iconbox__icon {
            @media (min-width: 1200px) {
                position: absolute;
                left: -5.5rem;
            }
        }
    }
}

// --- Map

.advertisement__map {
    position: relative;

    &::before {
        position: absolute;
        top: calc(-3rem + 10px);
        left: 0;
        width: 100%;
        height: 1px;
        background: $custom-color__border;
        content: '';
    }

    iframe {
        padding: 1rem;
        border-radius: 1.5rem;
        box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, .05);
        width: 100%;
        height: 390px;
    }
}

// Utilities

nav {
    box-shadow: $custom-box-shadow__card;
    display: block;
    z-index: 10;

    &.bg-light {
        background-color: white !important;
    }

    .container {
        padding: 0 2rem;
    }

    .navbar-brand {
        img {
            width: auto;
            height: 3rem;
            pointer-events: none;
        }
    }

    .navbar-nav {
        margin-left: auto;
        align-items: center;
        gap: 1rem;

        @media (max-width: 991px) {
            margin-top: 1.5rem;
        }
    }

    .btn-link {
        color: $custom-color__body;
        text-decoration: none;
    }

    &.navigation__homepage {
        box-shadow: none;

        &.bg-light {
            background-color: transparent !important;
        }

        .navbar-brand {
            img {
                width: auto;
                pointer-events: none;

                @media (min-width: 992px) {
                    height: 4.25rem;
                }

                @media (min-width: 1200px) {
                    height: 4.25rem;
                }

                @media (min-width: 1400px) {
                    height: 5.25rem;
                }
            }
        }
    }
}

// --------------------------------------------------

// Utilities

// --- Borders

img {
    &.rounded {
        border-radius: 1.5rem !important;
    }
}

// --------------------------------------------------































a {
    color: $custom-color__body;

    &:hover,
    &:focus {
        color: $custom-color__body--faded;
    }
}

footer {
    background-color: #F9F9F9;

    .nav-link {
        color: $custom-color__body;
        font-weight: bold;

        &:hover,
        &:focus {
            color: $custom-color__body--faded;
        }
    }

    p {
        color: #666666;

        a {
            color: inherit;
        }
    }
}

.form__field_with_icon {
    position: relative;

    label {
        margin-left: 30px !important;
        position: absolute;
        top: -15px;
    }

    input,
    select,
    textarea {
        padding-left: 55px !important;
        margin-top: 0 !important;
    }

    .left-icon {
        position: absolute;
        left: 17px;
        top: 20px;
        font-size: 22px;
        color: $primary;
    }
}

.form__contact-form {
    input[type="checkbox"] {
        padding: 10px !important;
        margin-top: 2px !important;
    }
}

.form__ad {
    input[type="checkbox"] {
        padding: 10px !important;
        margin-left: 5px;
        margin-top: 2px !important;
    }
}

.left-top-absolute {
    border-radius: 12px;
    left: 25px;
    position: absolute;
    top: 25px;
    z-index: 2;

    .white-badge {
        background: #fff;
        border-radius: 12px;
        color: #333;
        font-size: 13px;
        font-weight: 500;
        padding: 0 7px;
        z-index: 2;
        margin-bottom: 10px;
        display: table;

        &.stars {
            background: #fff;
            color: #333;
            padding: 0px 7px;
        }

        &.notice {
            background: $primary;
            color: #333;
        }
    }
}

.notice-badge {
    background: $primary;
    color: #333 !important;
    padding: 5px 12px;
    border-radius: 12px;
    display: inline-block;
}
